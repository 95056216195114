import { authRedirectUrl } from '@admin/src/lib/util'
import { combinePaths, IReturn, JsonServiceClient } from "@servicestack/client";

import { SessionInfo } from './auth.dtos'
import { Authenticate, GetAdminSites, ResponseStatus } from './dtos'

const BaseUrl = '/'

export class HocoosJsonServiceClient extends JsonServiceClient {

  constructor(baseUrl: string) {
    super(baseUrl)
    this.parseJson = this.customParseJson
  }

  createUrlFromDto<T>(method: string, request: IReturn<T>): string {
    let url = super.createUrlFromDto<T>(method, request);
    var urlPrefix = request["getApiRoute"] && request["getApiRoute"]() || '/api/web/';
    const newReplyRoute = combinePaths(urlPrefix + this.replyBaseUrl) + "/";
    return url.replace(this.replyBaseUrl, newReplyRoute);
  }

  customParseJson(res: Response): Promise<any> {
    return res.text().then(txt => {
      return txt.length > 0 ? JSON.parse(txt) : null
    })
  }
}

export const client = new HocoosJsonServiceClient(BaseUrl);

export const getSessionInfo = async () => {
  try {
    return await client.get(new SessionInfo())
  } catch (e) {
    return null
  }
}

// export const intiApi = (baseUrl) => new JsonServiceClient(baseUrl)
// export const apiWebPath = '/api/web/'
// export const apiAuthPath = '/api/auth/'
// export const api = intiApi(baseUrl) as any
// export const authApi = intiApi(apiAuthPath)

export const getAdminSites = async () => await client.get(new GetAdminSites())

export const checkAuth = async () => {
  try {
    //Converts Session to JWT Token Cookie
    // const authResponse = await api.post(new ConvertSessionToToken());
    const [response] = await Promise.all([client.get(new SessionInfo())]);

    //Remove unnecessary JWT from HTTP Headers so only JWT Cookie is used
    (client as any).bearerToken = (response as any).accessToken = null
    client.headers.delete('Authorization')
    // api.headers.delete('Authorization');

    return response
  } catch (e) {
    return null
  }
}

export const auth = async (request) => {
  const response: any /* XXX: */ = await client.post(request)
  return response
}

export const signout = async () => {
  await client.post(new Authenticate({ provider: 'logout' }))
}

export const logout = async () => {
  const request = new Authenticate()
  request.provider = 'logout'
  await client.post(request)
}

const errorsJson = {
  ['Unauthorized']: {
    title: 'Unauthorized',
    body: 'Please login',
  },
}

const getError = (responseStatus: ResponseStatus) => {
  const { errorCode, message } = responseStatus as ResponseStatus
  if (errorsJson[errorCode]) {
    return errorsJson[errorCode]
  }
  return {
    title: 'Error',
    body: message,
  }
}

export const handleErrors = (error, vueInstance) => {
  const { responseStatus } = error
  const { errorCode } = responseStatus as ResponseStatus
  const { title, body } = getError(responseStatus)

  vueInstance.$snotify.error(body, title, {
    timeout: 2000,
    showProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
  })

  if (errorCode == 'Unauthorized') {
    vueInstance.$store.commit('SET_USER_SESSION', null)
    const redirectUrl = authRedirectUrl(null, vueInstance.$route) || '/login'
    vueInstance.$router.push(redirectUrl)
  }
}
