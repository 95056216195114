<template>
  <div class="layout-footer">
    <span class="font-medium ml-2">Hocoos Admin</span>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    },
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg'
    },
  },
}
</script>
