<template>
  <div class="surface-0 flex align-items-center justify-content-center min-h-screen overflow-hidden">
      <div class="box" style="--i:1;"></div>
      <p class="error-page-title">Error</p>
      <div class="box" style="top: -6px;"></div>
  </div>
</template>

<style scoped lang="scss">
.error-page-title {
  font-size: 50px;
  color: red;
  background: #FFF;
  border-radius: 8px;
  text-transform: uppercase;
  font-weight: 800;
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
}

.box {
  position: relative;
  width: 100%;
  height: 100vh;
  background: linear-gradient(
    45deg,
    #f30008 10%,
    transparent 10%,
    transparent 50%,
    #f30000 50%,
    #f3008e 60%,
    transparent 60%,
    transparent 100%
  );
  background-size: 40px 40px;
  transform: rotate(2 * 90deg);
  animation: animate 0.3s linear infinite;
}

@keyframes animate {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 40px;
  }
}

</style>
