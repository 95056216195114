import { createRouter, createWebHashHistory } from 'vue-router'

import store from './store/index'

import { AuthRole } from './shared/auth.dtos'
import { checkAuth } from './shared/gateway'
import { SessionInfoResponse } from './shared/auth.dtos'

const routes = [
  // {
  //     path: '/',
  //     name: 'dashboard',
  //     component: Dashboard,
  // },
  {
    path: '/',
    name: 'sitesDashboard',
    component: () => import('./pages/sites/list.vue')
  },
  //
  {
    path: '/sites-new',
    name: 'sites-new',
    component: () => import('./pages/sites/list.vue')
  },
  {
    path: '/site/:id',
    name: 'site',
    component: () => import('./components/hocoos/Site/view.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/sites/daily',
    name: 'sites-daily',
    component: () => import('./components/hocoos/SitesDaily.vue'),
  },
  {
    path: '/sites/monthly',
    name: 'sites-monthly',
    component: () => import('./components/hocoos/SitesMonthly.vue'),
  },
  {
    path: '/wizard-progresses',
    name: 'wizard-progresses',
    component: () => import('./pages/wizard/progress/list.vue'),
  },
  {
    path: '/site-suspensions',
    name: 'site-suspensions',
    component: () => import('./pages/suspensions/list.vue')
  },
  {
    path: '/pages',
    name: 'pages',
    component: () => import('./components/hocoos/Page/list.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/page/:id',
    name: 'page',
    component: () => import('./components/hocoos/Page/view.vue'),
    props: (route) => ({ query: route.query }),
  },

  //Email templates
  {
    path: '/email-templates',
    name: 'email-templates',
    component: () => import('./components/hocoos/EmailTemplates/Index.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-templates/:id',
    name: 'edit-email-template',
    component: () => import('./components/hocoos/EmailTemplates/EmailTemplate.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-templates/create',
    name: 'create-email-template',
    component: () => import('./components/hocoos/EmailTemplates/EmailTemplate.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-templates-logs',
    name: 'email-templates-logs',
    component: () => import('./components/hocoos/EmailTemplates/EmailLogs.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-templates-logs/:id',
    name: 'view-email-templates-logs',
    component: () => import('./components/hocoos/EmailTemplates/EmailLog.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-demo-data-list',
    name: 'email-demo-data-list',
    component: () => import('./components/hocoos/EmailDemoData/list.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-demo-data-create',
    name: 'email-demo-data-create',
    component: () => import('./components/hocoos/EmailDemoData/edit.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-demo-data-edit/:id',
    name: 'email-demo-data-edit',
    component: () => import('./components/hocoos/EmailDemoData/edit.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/email-templates-changes-logs',
    name: 'email-templates-changes-logs',
    component: () => import('./components/hocoos/EmailTemplates/EmailChangesLogs.vue'),
    props: (route) => ({ query: route.query }),
  },
  //utils
  {
    path: '/buckets',
    name: 'buckets',
    component: () => import('./components/hocoos/Buckets/Index.vue'),
  },
  {
    path: '/buckets/:id',
    name: 'bucket-edit',
    component: () => import('./components/hocoos/Buckets/Bucket.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('./components/hocoos/Setting/list.vue'),
  },
  {
    path: '/setting/:id',
    name: 'setting-edit',
    component: () => import('./components/hocoos/Setting/view.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/processed-records',
    name: 'processed-records',
    component: () => import('./components/hocoos/ProcessedRecords/list.vue'),
  },
  {
    path: '/processed-records/:id',
    name: 'processed-records-edit',
    component: () => import('./components/hocoos/ProcessedRecords/view.vue'),
    props: (route) => ({ query: route.query }),
  },
  //file
  {
    path: '/videos',
    name: 'videos',
    component: () => import('./pages/files/videos/list.vue'),
  },
  {
    path: '/images',
    name: 'images',
    component: () => import('./pages/files/images/list.vue'),
  },
  {
    path: '/documents',
    name: 'documents',
    component: () => import('./pages/files/documents/list.vue'),
  },
  {
    path: '/audios',
    name: 'audios',
    component: () => import('./pages/files/audios/list.vue'),
  },
  //log
  {
    path: '/event-logs',
    name: 'event-logs',
    component: () => import('./pages/logs/events/list.vue'),
    props: (route) => ({ syncQueryParams: Boolean(route.query.syncQueryParams) }),
  },
  {
    path: '/event-log/:id',
    name: 'event-log',
    component: () => import('./pages/logs/events/view.vue'),
  },
  {
    path: '/log-entities',
    name: 'log-entities',
    props: true,
    component: () => import('./components/hocoos/Log/Entity/list.vue'),
  },
  {
    path: '/log-entity/:id',
    name: 'log-entity',
    component: () => import('./components/hocoos/Log/Entity/view.vue'),
  },
  {
    path: '/log-requests',
    name: 'log-requests',
    component: () => import('./components/hocoos/Log/Request/list.vue'),
  },
  {
    path: '/log-requests-new',
    name: 'log-requests-new',
    component: () => import('./pages/logs/requests/list.vue'),
    props: (route) => ({ syncQueryParams: Boolean(route.query.syncQueryParams) }),
  },
  {
    path: '/message-queueing',
    name: 'message-queueing',
    component: () => import('./components/hocoos/Log/MessageQueueing/list.vue'),
  },
  {
    path: '/message-queueing/info',
    name: 'message-queueing/info',
    component: () => import('./components/hocoos/Log/MessageQueueing/view.vue'),
    props: (route) => ({ queueName: route.query.queueName }),
  },
  {
    path: '/search-content',
    name: 'search-content',
    component: () => import('./components/hocoos/Log/SearchContent/view.vue'),
  },
  {
    path: '/log-request/:id',
    name: 'log-request',
    component: () => import('./components/hocoos/Log/Request/view.vue'),
  },
  {
    path: '/log-ai-user-history-list',
    name: 'log-ai-user-history-list',
    component: () => import('./components/hocoos/Log/AiUserHistory/list.vue'),
  },
  {
    path: '/log-ai-user-history/:id',
    name: 'log-ai-user-history',
    component: () => import('./components/hocoos/Log/AiUserHistory/view.vue'),
  },
  //localize
  {
    path: '/category-lcz-config',
    name: 'category-lcz-config',
    component: () => import('./pages/lcz/Category/view.vue'),
  },
  {
    path: '/industry-lcz-config',
    name: 'industry-lcz-config',
    component: () => import('./pages/lcz/Industry/view.vue'),
  },
  {
    path: '/group-lcz-config',
    name: 'group-lcz-config',
    component: () => import('./pages/lcz/Group/view.vue'),
  },
  {
    path: '/content-pack-lcz-config',
    name: 'content-pack-lcz-config',
    component: () => import('./pages/lcz/ContentPack/view.vue'),
  },
  {
    path: '/content-pack-rephrase-lcz-config',
    name: 'content-pack-rephrase-lcz-config',
    component: () => import('./pages/lcz/ContentPackRephrase/view.vue'),
  },
  {
    path: '/default-content-pages',
    name: 'default-content-pages',
    component: () => import('./pages/lcz/DefaultContentPage/list.vue'),
  },
  {
    path: '/def-product-lcz-config',
    name: 'def-product-lcz-config',
    component: () => import('./pages/lcz/DefProduct/view.vue'),
  },
  {
    path: '/global-lcz-config',
    name: 'global-lcz-config',
    component: () => import('./pages/lcz/Global/view.vue'),
  },

  {
    path: '/group/:id',
    name: 'group',
    component: () => import('./components/hocoos/Segments/Group.vue'),
    props: (route) => ({ query: route.query }),
  },
  {
    path: '/groups',
    name: 'groups',
    component: () => import('./components/hocoos/Segments/Groups.vue'),
  },
  {
    path: '/test-site-rules',
    name: 'test-site-rules',
    component: () => import('./components/hocoos/TestSiteRules.vue'),
  },
  {
    path: '/specifications',
    name: 'specifications',
    component: () => import('./components/hocoos/Segments/Specifications.vue'),
  },
  {
    path: '/specification/:id',
    name: 'specification',
    component: () => import('./components/hocoos/Segments/Specification.vue'),
    props: (route) => ({ query: route.query }),
  },
  //users
  {
    path: '/users',
    name: 'users',
    component: () => import('./components/hocoos/User/list.vue'),
  },
  {
    path: '/users/daily',
    name: 'users-daily',
    component: () => import('./components/hocoos/UsersDaily.vue'),
  },
  {
    path: '/users/monthly',
    name: 'users-monthly',
    component: () => import('./components/hocoos/UsersMonthly.vue'),
  },

  {
    path: '/user/:id',
    name: 'user',
    component: () => import('./components/hocoos/User/view.vue'),
    props: (route) => ({ query: route.query }),
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/hocoos/Login.vue'),
  },

  {
    path: '/empty',
    name: 'empty',
    component: () => import('./components/EmptyPage.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('./pages/Error.vue'),
  },
  {
    path: '/notfound',
    name: 'notfound',
    component: () => import('./pages/NotFound.vue'),
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('./pages/Access.vue'),
  },
  { path: '/:catchAll(.*)', name: 'Not Found', component: () => import('./pages/Error.vue') },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = store.getters.getUseAuthId()

  const userSession = (await checkAuth()) as SessionInfoResponse

  if (authRequired && !loggedIn && !userSession) {
    next('/login')
  } else {
    if (userSession && userSession?.mainRole == AuthRole.Admin) {
      next()
    } else {
      next()
      next('/login')
    }
  }
})

export default router
