import { SessionInfoResponse } from '@admin/src/shared/auth.dtos'
import { logout } from '../shared/gateway'
import { reactive, readonly } from 'vue'
import { useCookies } from 'vue3-cookies'

const { cookies } = useCookies()

const state = reactive({
  userSession: {} as SessionInfoResponse,
  mainRole: null,
})

const methods = {
  setUserSession(userSession) {
    state.userSession = userSession
    if (userSession) {
      const userAuthId = userSession.userAuthId || userSession.userId
      if (userAuthId) {
        state.mainRole = userSession.mainRole
        cookies.set('userAuthId', userAuthId)
      }
    } else {
      cookies.remove('userAuthId')
    }
  },

  async logout(state) {
    await logout()
    cookies.remove('userAuthId')
  },
}

const getters = {
  getUserSession() {
    return state.userSession
  },

  getUseAuthId() {
    return cookies.get('userAuthId')
  },
}

export default {
  state: readonly(state),
  methods,
  getters,
}
