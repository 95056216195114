<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <!-- <img alt="Logo" :src="topbarImage()" /> -->
      <span>Hocoos Admin</span>
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars" />
    </button>

    <button
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
      class="p-link layout-topbar-menu-button layout-topbar-button"
    >
      <i class="pi pi-ellipsis-v" />
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <!-- <li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-calendar"></i>
					<span>Events</span>
				</button>
			</li>
			<li>
				<button class="p-link layout-topbar-button">
					<i class="pi pi-cog"></i>
					<span>Settings</span>
				</button>
			</li> -->
      <li>
        <!-- <button class="p-link layout-topbar-button">
					<i class="pi pi-user"></i>
					<span>Profile</span>
				</button> -->
        <button class="p-link layout-topbar-button" label="Toggle" @click="toggle">
          <i class="pi pi-user" />
          <span class="ml-2">Profile</span>
        </button>
        <Menu ref="menu" :model="items" :popup="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import store from './store/index.ts'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()

export default {
  data() {
    return {
      items: [
        {
          label: 'Logout',
          icon: 'pi pi-refresh',
          command: () => {
            this.logout()
          },
        },
      ],
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    },
  },

  methods: {
    onMenuToggle(event) {
      this.$emit('menu-toggle', event)
    },
    onTopbarMenuToggle(event) {
      this.$emit('topbar-menu-toggle', event)
    },
    topbarImage() {
      return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg'
    },

    logout() {
      store.methods.logout()
      this.$router.replace('/login')
    },

    toggle(event) {
      this.$refs.menu.toggle(event)
    },
  },
}
</script>
